<template>
  <v-container class="mt-5">
    <div class="Title" >
      <h1 style="word-break: break-all; text-h6" >
        <router-link 
          style="color:#4ECDC4; text-decoration: none;"
          class="text-h6"
          :to="`/navi#${$route.query.hash}`"
          >
          ナビゲーション
        </router-link>
         
         
         <span class="text-h6">> {{this.navi.naviName}}</span>  <v-btn icon @click="dialogQRCode=true;"><v-icon>{{icons.qrcode}}</v-icon></v-btn>  
      </h1>
        <div class="mx-auto" style="max-width:1400px">

          <div :class="$vuetify.breakpoint.xs? 'my-3 d-flex flex-column ':'mt-4 d-flex'" style="font-weight:200; color:grey;"> <div class="mr-5">表示回数: {{navi.naviCount}}回</div>  <div>アップロード日: {{navi.createTime}}</div></div>

          <div class="d-flex justify-end" style="width:100%;" >

              <v-btn v-if="isOwn"
                color="#37e5d6"
                elevation="0"
                outlined
                class="mt-1 mr-3   "
                :loading="loadingSave"
                @click="dialogPublish = true"
              > {{convertPublishName}} </v-btn>

            <div>
              <v-btn v-if="isOwn"
                color="#37e5d6"
                elevation="2"
                class="mt-1 mr-3 white--text  "
                :loading="loadingSave"
                :disabled="!isChanged || loadingSave "
                @click="save"
              > 保存 </v-btn>
            </div>
          </div>

          <v-row class=" ma-0 pa-0 " width="100%">
            <v-col class="ma-0 pb-0" md="5" lg="4">
              <NaviThumbnail
                :thumbnail="naviChanged.thumbnail"
                :naviId="navi.naviId"
                :loading="loading"
                :change="toggleChange"
                :isOwn="isOwn"
                v-on:saveEmit="saveThumbnail"
              />
          </v-col>



            <v-col class="ma-0 pa-0" lg="8" md="7" sm=12 cols="12">
              <v-card
                tile
                elevation="0"
                width="100%"
                height="30%"
                :loading="loading "
              >
                <v-card-subtitle class="pb-0 ma-0 pt-2" ></v-card-subtitle>
                <v-text-field
                  outlined
                  :clearable="isOwn"
                  hide-details="false"
                  class="px-3 rounded"
                  height="50"
                  v-model="naviChanged.naviName"
                  label="名前"
                  :disabled="loadingSave"
                  :readonly="!isOwn"
                ></v-text-field>  
              </v-card>

             <v-card
                tile
                width="100%"
                height="47%"
                elevation="0"
                class="mt-2 pa-0"
                :loading="loading"
              >
              <v-col
                cols="12"
                class="py-0 my-0"
              >
              <v-textarea
                outlined
                :clearable="isOwn"
                hide-details="false"
                class="pt-2 rounded"
                label="説明"
                height="137"
                v-model="naviChanged.naviDetail"
                :disabled="loadingSave "
                :readonly="!isOwn"
              ></v-textarea>
              </v-col>
              </v-card>

            </v-col>
          </v-row>
        

          
          <h2  class="mt-5"> > ウェイポイント</h2>
          <WaypointsTable
            ref="child"
            :resetSelect="resetSelect"
            :isOwn="isOwn"
            v-on:selectedWaypointId="selectedWaypointId"
            v-on:dynamicIdList="changeDynamicIdList"
          />


        </div>
    </div>

    <DialogStatus
      v-if="sItem.type"
      :item="sItem"
      v-on:closeEmit="close" 
    />

    <v-dialog v-model="dialogPublish" max-width="600px" loading="false" persistent>
    <DialogPublish
      :item="navi"
      :isTable="false"
      v-on:closeEmit="close"
      v-on:savePublishEmit="savePublish"
    />
    </v-dialog>

    <v-dialog v-model="dialogQRCode" max-width="600px" persistent>
      <DialogQRCode
        v-on:closeQRCodeEmit="close"
        :name="navi.naviName"
        :id="$route.params.id"
      />
    </v-dialog>

  <!-- <v-snackbar
      v-model="isChanged"
      color="#37e5d6"
      :timeout="-1"
      :elevation=3
        key="bottom"
      top
      right
    >
      変更が保存されていません。
      <template v-slot:action="{ attrs }">

        <v-btn
          :loading="loading"
          color="white"
          text
          v-bind="attrs"
          @click="save()"
        >
            保存
        </v-btn>
  
      </template>
    </v-snackbar>  -->

  </v-container>



</template>

<script>
  import axios from "axios"

  const WaypointsTable = () => import('@/components/WaypointsTable.vue');
  const NaviThumbnail = () => import('@/components/NaviThumbnail.vue');
  const DialogStatus = () => import('@/components/DialogStatus.vue');
  const DialogPublish = () => import('@/components/DialogPublish.vue');
  const DialogQRCode = () => import('@/components/DialogQRCode.vue');
  import { mdiQrcode } from '@mdi/js'

  export default {
    name: 'NaviDetail',
    components:{
      WaypointsTable,
      NaviThumbnail,
      DialogStatus,
      DialogPublish,
      DialogQRCode
    },
    data: () => ({
      icons:{
        "qrcode":mdiQrcode
      },
      loading:true,
      loadingSave:false,
      isChanged: false,
      isError: false,
      isComplete:false,
      toggleChange:false,
      resetSelect: false,
      dialogPublish:false,  
      dialogQRCode:false,
      isOwn:false,
      naviList:[],
      changedWaypoints:[],
      navi:{
        naviName:"",
        naviDetail:"",
        naviThumbnail:"",
      },
      naviChanged:{
        naviName:"",
        naviDetail:"",
        naviThumbnail:"",
        publish:"",
        sharedUsers:[],
      },
      sItem:{
        dialog:false,
        type:"",
        text:`通信中...`,
        loadText:"通信中...",
        compText:"サムネイルの変更が完了しました。",
        errorText:"エラーが発生しました。",
        toTop:"true",
        close:true,
      },

    }),

    
    async created() {
      await this.getData();
      await this.checkOwn();
    },

    computed:{

      convertPublishName(){
        switch(this.navi.publish){
          case 'unlisted' : return "非公開" 
          case 'private'  : return "限定公開"
          case 'public'   : return "全体公開"
          default:  return  "?"
        }
      }
    },

    beforeRouteLeave (to, from, next) {
      if(this.isChanged){
      const answer = window.confirm("編集中のものは保存されませんが、よろしいですか？")
      if (answer) {
        next()
      } else {
        next(false)
      }
      }else{
        next()
      }
      
    },

    methods : {
      // naviIDが自分のものなのかどうか確認
      checkOwn: async function () {
        await axios({
          method: "GET",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
        }).then((response) => {
          this.naviList = response.data.results;
        });
        this.isOwn = this.naviList.some((n) => n.naviId == this.$route.params.id );

      },

      // ナビの詳細データ取得
      getData: async function () {
      this.loading = true;
      this.items = [];

      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.$route.params.id}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json"},
        }).then((response) => {
          console.log(response)
          this.navi = response.data.results

          if(this.navi !== null){
            Object.assign(this.naviChanged, this.navi)
            this.loading = false;
          }else{
            this.loading = false;
            this.sItem.type = "error";
            this.sItem.errorText = "URLが間違っている可能性があります。";
          }
    
          // console.log(this.navi)
     
        }).catch((err) => {
          console.log(err.response)
          this.sItem.type = "error";
          this.sItem.errorText = "予期せぬエラーが起きました。";
       
        } );


      },

      //変更後のサムネイルを画面に適用
      saveThumbnail(file){
        this.navi.thumbnail = file;
        this.naviChanged.thumbnail = file;
        this.resetSelect = !this.resetSelect;
        this.checkNaviChange();
      },

      //公開設定を変更し保存。
      savePublish(publish,sharedUsers){
        this.naviChanged.publish = publish;
        this.naviChanged.sharedUsers = sharedUsers;
        this.isChanged = true;
        this.save();
        this.close();
      },

      //詳細データの変更
      save(){
        if(this.isChanged){
          this.loading = true;
          this.loadingSave = true;
          let isThumb = false;
          let data = {};
          data = {
            "NaviName":this.naviChanged.naviName,
            "NaviDetail":this.naviChanged.naviDetail
          }
          if(this.naviChanged.thumbnail != this.navi.thumbnail){
            data["Thumbnail"] = this.naviChanged.thumbnail;
            isThumb = true;
          }
          if(this.naviChanged.publish != this.navi.publish){
            data["Publish"] = this.naviChanged.publish;
          }
          
          if(this.changedWaypoints.length != 0){
            data["Waypoints"] = this.changedWaypoints;
          }
        
          data["SharedUsers"] = this.naviChanged.sharedUsers;
      

          axios({
            method: "PUT",
            url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.$route.params.id}`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
            data:data,
          }).then((response) => {
            
            Object.assign(this.navi, this.naviChanged)
            this.isChanged = false;
            this.loading = false;
            this.toggleChange = !this.toggleChange;
            this.loadingSave = false;
            if( response.data.results !="complete" ){
              this.sItem.type = "error";
              this.sItem.errorText = "予期せぬエラーが起きました。";
            }
            else{
              this.isComplete = true;
              this.changedWaypoints = [];
              if(isThumb) this.naviChanged.thumbnail = this.tmpWaypointThumbnail;
              this.$refs.child.completedSave();

            }
         
          }).catch(() => {
            this.loadingSave = false;
            this.isError = true;
      
          });

        }
      },
      
      //ウェイポイントのサムネイルに変更する準備
      selectedWaypointId(waypointId,thumbnail){
        if(waypointId != "no"){
            this.naviChanged.thumbnail = waypointId;
            this.tmpWaypointThumbnail = thumbnail;
        }else{
            this.naviChanged.thumbnail = this.navi.thumbnail;
        } 
        this.checkNaviChange();
     
      },

      //dynamicの変更する準備
      changeDynamicIdList(waypoint){
        this.changedWaypoints = JSON.stringify(waypoint); // JSON文字列化
        this.changedWaypoints = JSON.parse(this.changedWaypoints); // JSON文字列化したものを戻す
        for(let i = 0; i <  this.changedWaypoints.length; i++){
          delete this.changedWaypoints[i]["thumbnail"];
          let nest_a = this.changedWaypoints[i].contents;
          for(let n = 0; n < nest_a.length; n++){
            let content = {
              "Id": nest_a[n]["id"],
              "Tag": nest_a[n]["tag"],
              "Position": nest_a[n]["position"],
              "Rotation": nest_a[n]["rotation"],
              "Scale": nest_a[n]["scale"],
              "Dynamic": nest_a[n]["dynamic"]
            };
            nest_a[n] = content;
          }
        }
        this.checkNaviChange();
     
      },

      //ナビが変更されたか確認。変更されていた場合、保存が可能になる。
      checkNaviChange(){
        this.isChanged = (this.naviChanged.naviName != "") && (this.naviChanged.thumbnail != "") 
          && (this.navi.naviName != this.naviChanged.naviName || this.navi.naviDetail != this.naviChanged.naviDetail || this.navi.thumbnail != this.naviChanged.thumbnail  || this.changedWaypoints.length != 0)

      },

      close (){
        this.sItem.type = "";
        this.dialogPublish = false;
        this.dialogQRCode = false;
        this.dialogImage = false;
      }
 

    },
    watch :{
      naviChanged : {
         handler: function () {
           this.checkNaviChange();
        },
        deep: true
      }
    }
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

  h2 {
    color: gray;
    font-weight: 200;
  }

</style>
